<template>
  <div>
    <validation-observer v-slot="{ invalid }">
      <option-card
        titleLabel="Negotiator Pay Less"
        :p1="ic.payLessTitle"
        :p2="ic.payLessContent"
        :p3="ic.payLessButton"
        label1="Pay Less Title*"
        label2="Pay Less Content*"
        label3="Pay Less Button*"
        imgSrc="@/assets/pay_less_guide.png"
        imgAlt="Pay Less Guide"
        :passedRules="negotiatorRuleObj"
        emitName="negotiator-pay-less-updated"
        @negotiator-pay-less-updated="updateNegotiatorPayLess"
      />
      <div class="field">
        <p class="content"><em>* indicates required field</em></p>
      </div>
      <div class="field">
        <div class="buttons">
          <button :disabled="invalid" class="button is-success" @click="save">
            Save
          </button>
          <button :disabled="invalid" class="button is-info" @click="saveNext">
            Save and Next
          </button>
          <button class="button is-light" @click="revert">
            Revert
          </button>
        </div>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import OptionCard from "@/components/instance/OptionCard.vue";
import { ValidationObserver } from "vee-validate";
import saveRevert from "@/mixins/negotiatorMixins";

export default {
  mixins: [saveRevert],
  components: {
    OptionCard,
    ValidationObserver
  },
  data() {
    return {
      ic: {
        payLessTitle: null,
        payLessContent: null,
        payLessButton: null
      }
    };
  },
  beforeRouteEnter(to, from, next) {
    next(async vm => {
      vm.$store.commit("SHOW_PROGRESS");
      vm.$store.commit("HIDE_NOTIFICATION");
      try {
        await vm.fetchPaymentPortalInstanceConfig(vm.$route.params.id);
        await vm.fetchInstances();
        vm.loadView.call(vm);
      } catch (e) {
        vm.$store.commit(
          "SHOW_NOTIFICATION",
          `Error loading InstanceNegotiator; ${e}`
        );
      } finally {
        vm.$store.commit("HIDE_PROGRESS");
      }
    });
  },
  async beforeRouteUpdate(to, from, next) {
    this.$store.commit("SHOW_PROGRESS");
    this.$store.commit("HIDE_NOTIFICATION");
    try {
      await this.fetchPaymentPortalInstanceConfig(to.params.id);
      this.loadView();
    } catch (e) {
      this.$store.commit(
        "SHOW_NOTIFICATION",
        `Error loading instance id=${to.params.id}; ${e}`
      );
    } finally {
      this.$store.commit("HIDE_PROGRESS");
      next();
    }
  },
  methods: {
    updateNegotiatorPayLess(payload) {
      this.ic.payLessTitle = payload.title;
      this.ic.payLessContent = payload.content;
      this.ic.payLessButton = payload.button;
    },
    loadView() {
      this.ic.instanceId = this.$route.params.id;
      this.ic.payLessTitle = this.$store.state.instanceConfig.payLessTitle;
      this.ic.payLessContent = this.$store.state.instanceConfig.payLessContent;
      this.ic.payLessButton = this.$store.state.instanceConfig.payLessButton;
    },
    async saveNext() {
      await this.save();
      this.$router.push({ name: "payLess" });
    }
  }
};
</script>
